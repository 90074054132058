<template>
  <div>
    <div class="d-flex mb-8">
      <div class="titulo-pagina">
        {{ tituloFormulario }}
      </div>
    </div>
    <v-form ref="form" class="row">
      <input-text
        v-model="form.nome"
        class="col-12 col-md-6"
        :label="$t('modulos.tipo_envio.formulario.nome')"
        :max="50"
        :regras-personalizadas="[regraNome]"
        obrigatorio
      />
      <input-select
        v-model="form.flagVisivelEnvioMaterial"
        :options="opcoes.boleano"
        class="col-12 col-md-3"
        :label="`${$t(
          'modulos.tipo_envio.formulario.visivel_envio_material'
        )} *`"
      />
      <input-select
        v-model="form.flagVisivelRoteirizacao"
        :options="opcoes.boleano"
        class="col-12 col-md-3"
        :label="`${$t('modulos.tipo_envio.formulario.visivel_roteirizacao')} *`"
      />
    </v-form>

    <v-divider class="my-5" />
    <div class="d-flex align-center justify-end">
      <botao-padrao outlined color="secondary" class="mr-2" @click="cancelar">
        <v-icon>$mdiCancel</v-icon>
        {{ $t('geral.botoes.cancelar') }}
      </botao-padrao>
      <botao-padrao :disabled="!valido" @click="confirmacaoSalvar">
        <v-icon>$mdiContentSaveOutline</v-icon>
        {{ $t('geral.botoes.salvar') }}
      </botao-padrao>
    </div>
  </div>
</template>
<script>
import TipoEnvioService from '@common/services/cadastros/TipoEnvioService';
import { TipoEnvioModel } from '@common/models/cadastros/TipoEnvioModel';
import helpers from '@common/utils/helpers';
export default {
  props: {
    id: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      valido: false,
      form: new TipoEnvioModel({}),
      opcoes: {
        boleano: helpers.BoleanoEnum,
      },
    };
  },
  computed: {
    tituloFormulario: function () {
      if (this.id) return this.$t('modulos.tipo_envio.titulos.editar');
      return this.$t('modulos.tipo_envio.titulos.novo');
    },
  },
  watch: {
    form: {
      handler() {
        if (this.form.nome) {
          this.valido =
            this.form.nome.length <= 50 && this.form.nome.trim() !== '';
        } else {
          this.valido = false;
        }
      },
      deep: true,
      immediate: true,
    },
  },
  mounted() {
    if (this.id) {
      helpers.redirecionarSemPermissao(this, 'TipoEnvio', 'Editar');
      this.buscar(this.id);
    }
    helpers.redirecionarSemPermissao(this, 'TipoEnvio', 'Inserir');

    this.$store.dispatch('Layout/alterarTituloPagina', this.tituloFormulario);
  },
  methods: {
    regraNome: function (v) {
      if (!v) return true;

      return v.trim() === ''
        ? this.$t('modulos.tipo_envio.erros.input_empty')
        : true;
    },
    buscar: function () {
      this.$store.dispatch('Layout/iniciarCarregamento');
      TipoEnvioService.buscar(this.id)
        .then((res) => {
          this.form = new TipoEnvioModel(res.data);
          this.valido = true;
        })
        .catch(() => {
          this.toastErro(this.$t('modulos.tipo_envio.erros.id_invalido'));
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
    confirmacaoSalvar: function () {
      if (this.id) {
        this.confirmarSalvar().then(() => {
          this.salvar();
        });
      } else this.salvar();
    },
    salvar: function () {
      if (this.$refs.form) this.valido = this.$refs.form.validate();
      if (!this.valido) return;
      this.$store.dispatch('Layout/iniciarCarregamento');
      TipoEnvioService.salvar(this.form)
        .then(() => {
          this.toastSucesso(this.$t(`modulos.tipo_envio.cadastro_sucesso`));
          this.$router.push({ name: 'tipo-envio' });
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
    cancelar: function () {
      this.confirmarCancelar().then(() => {
        this.$router.push({ name: 'tipo-envio' });
      });
    },
  },
};
</script>
